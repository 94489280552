
import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { FaLaravel } from "react-icons/fa";
import {useTypewriter,Cursor} from 'react-simple-typewriter'
import { IoLogoVue } from "react-icons/io5";
import { IoLogoJavascript } from "react-icons/io5"
import { FaPhp } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import Leftbanner from "./Leftbanner";

const Banner=()=>{
    const [text]=useTypewriter({
words:["Profesional web developer.","FullStack Developer.","UI Designer"],
loop: true, // 'true' for continuous loop
  typeSpeed: 20,
  delaySpeed: 2000,
  deleteSpeed: 2,

    });

    return (
       
<div class="relative font-sans before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
      <img src="https://readymadeui.com/cardImg.webp" alt="Banner Image" class="absolute inset-0 w-full h-full object-cover" />

      <div class="min-h-[350px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
      
        <div className="flex flex-col gap-1 h-[800px] w-full sm:mt-20 ">

    <h1 className="sm:text-sm sm:text-gray-300 text-gray-600 md:text-2xl  lg:text-4xl lg:text-gray-600 font-bold md:text-gray-600" id="">Hi, I'M{" "}
        <span className="text-xl md:text-2xl  lg:text-4xl font-bold text-blue-950 capitalize text-gray-600">Mustafa</span>
    </h1>
    <h2 className="text-xl text-violet-500">a <span>{text}</span><Cursor cursorBlinking="false"
    
    cursorStyle="|" cursorColor="#ff01f"/></h2>
    <div className="text-sm w-full">
    <p className=" text-white sm:w-[1/2] w-full h-auto w-full  md:w-[95%] h-[600px] lg:w-[100%]lg:text-white text-xl text-gray-600 font-titleFont" >I'm profesional web developer with over four years of experience in crafting world-class software solutions. My expertise spans a wide range of technologies, including Larvel, React, Vue.js, JavaScript, HTML, and CSS. I have successfully delivered projects in various domains, particularly in human resource management systems (HRMS).</p>
    </div>
    

<div className="flex flex-col mt-10 h-[100px] w-full">
    <h2 className=" text-white font-bold w-1/3">Best Skills ON</h2>
    <div className="     flex flex-row gap-5 mt-5  md:flex md:flex-row md:gap-5 md:mt-5 md:w-full lg:flex lg:flex-row lg:gap-5 lg:mt-5 lg:w-full">
   <span className="bannerIcon bg-blue-950"> <FaReact /></span>
   <span className="bannerIcon bg-blue-950"><IoLogoVue /></span> 
    <span className="bannerIcon bg-blue-950"><IoLogoJavascript /></span>
    <span className="bannerIcon bg-blue-950"><FaLaravel /></span>
    <span className="bannerIcon bg-blue-950"><FaPhp /></span>
    <span  className="bannerIcon bg-blue-950"><FaHtml5/></span>
    </div>
    </div>
  
</div>
      </div>
    </div>
    )
}
export default Banner;