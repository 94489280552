import React, { useState, useEffect } from "react";
import image4 from '../components/mustefa.jpg';
import { navLinksdata } from '../components/constants/index.js';
import { Link } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const Navd = document.querySelector('#scrols');

    const handleScroll = () => {
     
      
      


    
    };

    window.addEventListener('scroll', handleScroll);





    var toggleOpen = document.getElementById('toggleOpen');
    var toggleClose = document.getElementById('toggleClose');
    var collapseMenu = document.getElementById('collapseMenu');
  
    function handleClick() {
      if (collapseMenu.style.display === 'block') {
        collapseMenu.style.display = 'none';
      } else {
        collapseMenu.style.display = 'block';
      }
    }
  
    toggleOpen.addEventListener('click', handleClick);
    toggleClose.addEventListener('click', handleClick);




    // Cleanup function to remove the event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header class='bg-gray-300 lg:bg-gray-300 flex shadow-md py-4 px-4 sm:px-10 sm:bg-gray-300 bg-white font-[sans-serif] min-h-[80px] tracking-wide relative z-50'>
  <div class='flex flex-wrap items-center gap-5 w-full'>
    <h1 className="text-3x text-black" href="javascript:void(0)">
      Mustefa.k
    </h1>

    <div id="collapseMenu"
      class='max-lg:hidden lg:!flex lg:ml-auto max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50'>
      <button id="toggleClose" class='lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3'>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 fill-black" viewBox="0 0 320.591 320.591">
          <path
            d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
            data-original="#000000"></path>
          <path
            d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
            data-original="#000000"></path>
        </svg>
      </button>

      <ul  className='lg:flex gap-4 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50'>
          {navLinksdata.map(({ _id, title, link }) => (
            <li key={_id} className="max-lg:border-b max-lg:py-3 px-3">
             <Link
  activeClass="active"
  className="hover:text-[#007bff] text-[#333] font-semibold block text-[15px]"
  to={link}
  spy={true}
  smooth={true}
  offset={-70}
  duration={500}
  onClick={() => setIsOpen(false)}
  style={{ cursor: 'pointer' }} // Add this line
>
  {title}
</Link>

            </li>
          ))}
        </ul>




    </div>

    <div class='flex items-center max-lg:ml-auto space-x-6'>
      
       

      <button id="toggleOpen" class='lg:hidden'>
        <svg class="w-7 h-7" fill="#333" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
  </div>
</header>
  );
}

export default Navbar;
