import React, { useState } from 'react'
import RessumeCard from '../ressume/RessumeCard'
import Skills from './Skills'
import Education from './Education'
import Achivments from './Achivments'
import Experiance from './Experiance'

import {motion} from 'framer-motion'
const Myressume = () => {
    const ressumr=[

        {
            title:"BSc Degree in Computer Science",
            subtitle:"Madda Walabu University from(2014-2017)",
            res:"3.59"
        },
        {
            title:"Certificate of MEARN(Node js Expres js React js Mongo DB)",
            subtitle:"MEARN full stack guide from Udemy in 2024",
            res:"Passed All"
        }
        
    ]
    const [educationData, setEducationData]=useState(true)
    const [SkillData, setSkillData]=useState(false)
    const [ExperianceData, setExperianceData]=useState(false)
    const [AchivmentsData, setAchivmentsData]=useState(false)
  return (
    <section id="ressume" className='w-full border-b-[1px] py-10   text-xl text-gray-300  uppercase w-full justify-center item-center tex-center  md:w-full lg:w-1/2 justify-center item-center bg-gray-300  h-[1100px] lg:h-[800px] lg:w-full'>




        
<div className='flex  justify-center items-center text-center  xl:w-full'>


<h1 className='text-black '>4+Years of experiance</h1>
<h2 className='text-xl py-5 font-bold lg:tex-white '>My Ressume</h2>
</div>
<div className='flex flex-col justify-center item-center text-center w-full md:w-full '>
    <ul className=' w-full justify-center item-center text-center  md:w-full  lg:w-[95%] lg:text-xl xl:text-xl xl:w-full lg:w-full md:w-full grid grid-cols-4'> 
        <li onClick={()=>setEducationData(true)& 
            setSkillData(false)&setExperianceData(false)&
            setAchivmentsData(false)} className='ressumeLi '>Education</li>
        
        <li onClick={()=>setEducationData(false) & 
            setSkillData(true)&setExperianceData(false)&
            setAchivmentsData(false)} className='ressumeLi'>Professional Skills</li>
        
        <li onClick={()=>setEducationData(false) &
            setSkillData(false)&
            setExperianceData(true) 
            &setAchivmentsData(false)}className='ressumeLi'>Job Experiance</li>
        
        <li onClick={()=>setEducationData(false) &
            setSkillData(false)&
            setExperianceData(false) 
            &setAchivmentsData(true)}  className='ressumeLi'>Achievements</li>
    </ul>
</div>
<div className=' font-titleFont gap-10 bg-gray-300'>
   
    {educationData&&<Education/>}
    {SkillData&&<Skills/>}
    {ExperianceData&&<Experiance/>}
{AchivmentsData&& <Achivments/>}
  
</div>



    </section>
  
  
  )
}

export default Myressume
