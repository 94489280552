import React from 'react';
import { motion } from 'framer-motion';
import imagel from '../banner/rr.png';

function Leftbanner() {
  // Animation variants
 
  return (
    <div className="justify-spacebetween mt-10 bg-gray-300 rounded-md mt-10">
      <motion.img
        src={imagel}
        alt='description'
        className='sm:w-[200px]  sm:h-[300px] md:ml-auto md:w-[200px] md:h-[400px] lg:w-[400px] lg:mt-1 lg:h-[600px] lg:ml-10'
       id="leftimg"
      />
    </div>
  );
}

export default Leftbanner;
