import React from 'react'
import RessumeCard from '../ressume/RessumeCard'
import {motion} from 'framer-motion'
const  Achivments = () => {
    const ressumr=[

        {
            title:"Junior IT Officer",
            subtitle:"Addis Ababa City Administration from(2018-2021)",
            res:"more than two years"
        },
        {
            title:"Senior IT officer and Software developer",
            subtitle:"Charity and Development Association from (2021-2024(now))",
            res:"Passed All"
        }
    ]
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1,transition:{duration:.5}}} className='w-[80%] gap-1 h-[600px] border-l-[6px] border-black lg:w-[40%]lg:h-[800px] lg:flex lg:flex-row'>
   

   <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 lg:h-[200px]">
      <div class="p-6">
        <h3 class="text-lg font-semibold text-black ">Certificate Of MEARN from Udemy</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">JUNIOR IT OFFICER
ADDIS ABABA CITY ADMINISTRATION FROM(2018-2021)
MORE THAN THREE YEARS
          </p>
        
      </div>
    </div>



    <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 lg:h-[200px]" >
      <div class="p-6">
        <h3 class="text-lg font-semibold text-black">BSc Degree</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">I have done my Bsc degree from MWU by department of Computer Science in 2017
        </p>
        
      </div>
    </div>



</motion.div>
  )
}

export default Achivments
