export const navLinksdata=[{

    _id:"1",
    title:"Home",
    link:"home"
},
{
    _id:"2",
    title:"MyProjects",
    link:"works"
},
{
    id:"3",
    title:"Myfeature",
    link:"feature"
}, {
    _id:"5",
    title:"Ressume",
    link:"ressume"
},
    {
        _id:"4",
        title:"Testimonials",
        link:"testimonials"
    },
   
   {
    _id:"6",
    title:"Contact",
    link:"contact"
   }

]