import React from 'react';
import Title from '../layouts/Title';
import ProjectCard from '../works/ProjectCard';
import { FaGlobe } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import {motion,useInView,useAnimation,useIsPresent} from 'framer-motion'
import {useRef} from 'react'

import { useEffect } from 'react';
const Myworks = () => {
  const ref= useRef(null)
  const mainControls=useAnimation()
  const slideControls=useAnimation()
  const isInView=useInView(ref ,{once:true})
  const Effect = useEffect(()=>{
    console.log(isInView)
  },
[isInView])
  // Define an array of project objects
  const projects = [
    {
      title: "CDA-HRMS (Leave)",
      des: "The Leave module is a key component of the CDA-HRMS platform, designed to empower employees within an organization to seamlessly apply for leave.",
      img: "/leave.PNG",
      icon:<FaGlobe />,
      icon2:<FaGithub />
    },
    {
      title: "Dynamic Website for ACD Tax",
      des: "This dynamic website that is  a cutting-edge platform designed to showcase their expertise in tax consultancy. ",

      
      img: "/ac2.PNG",  
       icon:<FaGlobe />,
      icon2:<FaGithub />
    },
    {
      title: "Website for CDA(local NGO)",
      des: "This  dynamic website designed for the local non-governmental organization and it is  a vibrant and interactive platform that fully encapsulates the essence of their activities.",
      img: "cdaweb.PNG",
      icon: <a href="https://cdaethi.org"><FaGlobe /></a>,
      icon2:<FaGithub />
    },
    {
      title: "CDA-HRMS (Daily Employee Attendance)",
      des: "IN this featur of CDA-HRMS Employees can mark their attendance using their cell phones, eliminating the need for traditional time clocks or physical check-ins",
      img: "/pun.PNG",
      icon:<FaGlobe />,
      icon2:<FaGithub />
      
    },
    {
      title: "CDA-HRMS(Employee managment)",
      des: "The CDA-HRMS offers a comprehensive Employee Data Management feature that serves as a central hub for all employee-related information. This feature allows for register,upload,update and others.",
      img: "empman.jpg",
      icon:<FaGlobe />,
      icon2:<FaGithub />
    },
    {
      title: "CDA-HRMS(Employee Daily Actvity)",
      des: "The Daily Activity feature of the CDA-HRMS is an engaging tool that facilitates employees in documenting their daily tasks.After morning ",
      img: "/daily1.PNG",
       icon:<FaGlobe />,
      icon2:<FaGithub />
    },
    // ...add more projects as needed
  ];

  return (
    <div id="works" class="font-[sans-serif] text-gray-800 bg-gray-100 px-6 py-12">
    <div class="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
      <div class="text-left">
        <h2 class="text-4xl font-extrabold mb-6">Featured project CDA-HRMS</h2>
        This comprehensive human resource management system designed to streamline HR operations within an organization. It consists the following intersting features:-
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-8 mt-12">
          <div class="flex items-center">

            <h6 class="text-base font-semibold ml-4">Gps based Employee attendance system.</h6>
          </div>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" class="fill-green-500 shrink-0" viewBox="0 0 24 24">
              <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
            </svg>
            <h6 class="text-base font-semibold ml-4">Daily Activity Managment sytem.</h6>
          </div>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" class="fill-green-500 shrink-0" viewBox="0 0 24 24">
              <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
            </svg>
            <h6 class="text-base font-semibold ml-4">Performance and Evaluation managment system.</h6>
          </div>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" class="fill-green-500 shrink-0" viewBox="0 0 24 24">
              <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
            </svg>
            <h6 class="text-base font-semibold ml-4">Employee and Employe file  managment system.</h6>
          </div>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" class="fill-green-500 shrink-0" viewBox="0 0 24 24">
              <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
            </svg>
            <h6 class="text-base font-semibold ml-4">Budjet and Finance managment system.</h6>
          </div>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" class="fill-green-500 shrink-0" viewBox="0 0 24 24">
              <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
            </svg>
            <h6 class="text-base font-semibold ml-4">Leave Managment System.</h6>
           
          </div>
          <button className='bg-violet-500 text-white w-[100px] border rounded-md'><a href="https://hrm.cdaethi.org">View Project</a></button>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <img src="loginmob.jpg"  class="rounded-lg h-[300px]" />
      </div>
    </div>










    <div class="bg-gray-100 md:px-10 px-4 py-12 font-[sans-serif]">
      <div class="container mx-auto">
        <h2 class="text-3xl font-extrabold text-gray-800 mb-8">My Works</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div class="bg-white rounded-lg overflow-hidden shadow-lg">
            <img src="/daily1.PNG" alt="Blog Post 1" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">CDA-HRMS(Employee Daily Actvity)</h3>
              <p class="text-gray-600 text-sm">IN this featur of CDA-HRMS Employees can mark their attendance using their cell phones, eliminating the need for traditional time clocks or physical check-ins...</p>
                    <a className="text-blue-700"href="https://hrm.cdaethi.org">Readmore</a>
            </div>
          </div>
          <div class="bg-white rounded-lg overflow-hidden shadow-lg">
            <img src="/amna.png" alt="Blog Post 2" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">Dynamic website for Ngo</h3>
              <p class="text-gray-600 text-sm">This dynamic website that is  a cutting-edge platform designed to showcase their work..</p>
              <a className="text-blue-700"href="https://amanaassethi.org">Readmore</a>
            </div>
          </div>





          <div class="bg-white rounded-lg overflow-hidden shadow-lg">
            <img src="/lleave.png" alt="Blog Post 2" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">CDA-HRMS(Leave)</h3>
              <p class="text-gray-600 text-sm">The Leave module is a key component of the CDA-HRMS platform, designed to empower employees within an organization to seamlessly apply for leave...</p>
              <a className="text-blue-700"href="https://hrm.cdaethi.org">Readmore</a>
            </div>
          </div>



          <div class="bg-white rounded-lg overflow-hidden shadow-lg">
            <img src="/mobat.jpg" alt="Blog Post 2" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">CDA-HRMS(EMployee attendance)</h3>
              <p class="text-gray-600 text-sm">IN this featur of CDA-HRMS Employees can mark their attendance using their cell phones, eliminating the need for traditional time clocks or physical check-ins...</p>
              <a className="text-blue-700"href="https://hrm.cdaethi.org">Readmore</a>
            </div>
          </div>




          <div class="bg-white rounded-lg overflow-hidden shadow-lg">
            <img src="/eemploye.png" alt="Blog Post 2" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">CDA-HRMS Employee Managment System</h3>
              <p class="text-gray-600 text-sm">The CDA-HRMS offers a comprehensive Employee Data Management feature that serves as a central hub for all employee-related information. This feature allows for register,upload,update and others...</p>
              <a className="text-blue-700"href="https://hrm.cdaethi.org">Readmore</a>
            </div>
          </div>

          






          <div class="bg-white rounded-lg overflow-hidden  shadow-lg animate__animated animate__fadeInUp ">
            <img src="/pperf.png" alt="Blog Post 3" class="w-full h-52 object-cover" />
            <div class="p-6">
              <h3 class="text-xl font-bold text-gray-800 mb-2">CDA-HRMS (performance and Evaluation)</h3>
              <p class="text-gray-600 text-sm">This is among features of CDA-HRMS that helps the organization to get their performance based on daily acvity ..</p>
              <a className="text-blue-700"href="https://hrm.cdaethi.org">Readmore</a>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
  
  );
}

export default Myworks;
