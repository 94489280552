
import React  from 'react';
import './index.css';
import "slick-carousel/slick/slick.css";
import Navbar from './components/Navbar.js'
import Banner from '././components/banner/Banner.js'
import Myworks from '././components/works/Myworks.js'
import Testimonials from '././components/testimonial/Testimonial.js'
import Myfeatue from './components/feature/Myfeatue.js';
import Myressume from './components/ressume/Myressume.js';
import Myfots from './components/ressume/Myfots.js';
import Testimonial from '././components/testimonial/Testimonial.js';
import Contact from './components/contact/Contact.js';

function App() {
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText">
   <div className="max-w-screen-xl mx-auto"> 
<Navbar/>
<Banner/> 
<Myfeatue/>
<Myworks/>
<Myressume/>

<Testimonial/>
<Contact/>
<Myfots/>

</div>
    </div>
  );
}

export default App;
