import React from 'react'
import RessumeCard from '../ressume/RessumeCard'
import {motion} from 'framer-motion'
const  Achivments = () => {
    const ressumr=[

        {
            title:"Successfully developed manay softwares",
            subtitle:"Human Resour Managment System,School Information Managment Sytem,Hotel Managment System",
         
        },
        {
            title:"Successfully developed many dynamic websites",
            subtitle:"Many  for local organizations and some of them are shown on portfoli above",
        
        }
    ]
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1,transition:{duration:.5}}} className='w-[80%] gap-1 h-[600px] border-l-[6px] border-black lg:w-[40%]lg:h-[800px] lg:flex lg:flex-row'>
        <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 lg:h-[200px] lg:flex ">
      <div class="p-6">
        <h3 class="text-lg font-semibold text-black ">SUCCESSFULLY DEVELOPED MANAY SOFTWARES</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">HUMAN RESOUR MANAGMENT SYSTEM,SCHOOL INFORMATION MANAGMENT SYTEM,HOTEL MANAGMENT SYSTEM
          </p>
        
      </div>
    </div>



    <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 lg:h-[200px]" >
      <div class="p-6">
        <h3 class="text-lg font-semibold text-black">BSc Degree</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">I have done my Bsc degree from MWU by department of Computer Science in 2017
        </p>
        
      </div>
    </div>
</motion.div>
  )
}

export default Achivments
