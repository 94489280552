import React from 'react'
import RessumeCard from '../ressume/RessumeCard'
import {motion} from 'framer-motion'
const Skills = () => {
    const ressumr=[

        {
            title:"bjvcbkjab",
            subtitle:"Maddvknvjkbv hjB",
            res:"3.59"
        },
        {
            title:"Certificate of MEARN(Node js Expres js React js Mongo DB)",
            subtitle:"MEARN full stack guide from Udemy in 2024",
            res:"Passed All"
        }
    ]
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1,transition:{duration:.5}}} className=''>
      <div className='flex flex-row w-full h-[200px] mt-1'>
   <div className='w-full flex  flex-row  mt-1  '>

  
   <h1 className='text-sky-950 text-sm w-full justify-center item-center text-center'>I have the following profesional skills </h1>

   
  
   </div>
   
   




</div>
<div className='flex flex-row justify-center item-center bg-gradient-to-r from-purple-800 to-indigo-800'>

<div className='w-full flex-col justify-center item-center'>
 
<p className='text-sm font-bold px-10 '>Development Skills</p>
<p className='text-sm'>Laravel</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[90%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">90%</span>
  </motion.div>

</div>
<p className> Php</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">80%</span>
  </motion.div>

</div>



<p className>Vue js</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-1/2 h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">50%</span>
  </motion.div>

</div>

      
<p className>JavaScript</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">80%</span>
  </motion.div>

</div>



<p className>Tailwind css</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[70%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">70%</span>
  </motion.div>

</div>




<p className>HTML</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[90%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">90%</span>
  </motion.div>

</div>

<p>Css</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[90%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">90%</span>
  </motion.div>

</div>

</div>

<div className='flex flex-col  w-full py-10'>
<h2 className='text-xl font-bold py-10 '>Design Skills </h2>

<p className=''>Photoshop</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[60%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">60%</span>
  </motion.div>

</div>


      
<p className=''>Figma</p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[70%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">70%</span>
  </motion.div>

</div>

    
<p className=''>Illustrator </p>
<div className="relative w-1/2 h-[12px] inline-flex rounded-md mt-2 overflow-hidden">
 
<motion.div initial={{x:"-100%",opacity:1}}
animate={{x:0, opacity:1}}
transition={{duration:0.5,delay:0.5}}

className="w-[60%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md">
  <span className="absolute  transform h-[90px]translate-y-1/2 right-0 mr-2 bg-black px-1 text-xs">60%</span>
  </motion.div>

</div>

</div>
    </div>
   
    
    </motion.div>

  )
}

export default Skills
