import React from 'react'
import RessumeCard from '../ressume/RessumeCard'
import {motion} from 'framer-motion'
const Education = () => {
    const ressumr=[

        {
            title:"Computer Science Degree",
            subtitle:"MWU from(2014-2017)",
            res:"3.59"
        },
        {
            title:"Certificate of MEARN",
            subtitle:"MEARN  from Udemy in 2024",
            res:"Passed All"
        }
    ]
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1,transition:{duration:.5}}} className='w-[80%] gap-1 h-[100px] border-l-[6px] border-black md:w-[80%]lg:w-[40%]lg:h-[800px] lg:flex lg:flex-row'>
    <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 lg:h-[300px]">
      <div class="p-6 text-center justify-center item-center">
        <h3 class="text-lg font-semibold text-black ">Certificate Of MEARN from Udemy</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">I have fully completed the training from Udemy as MEARN(Mongo db,Express js ,React js and Node js ) developer on february 2024
          </p>
        
      </div>
    </div>



    <div
      class="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 sm:h-[300px] md:h-[300px] lg:h-[300px]" >
      <div class="p-6 text-center justify-center item-center">
        <h3 class="text-lg font-semibold text-black">BSc Degree</h3>
        <p class="mt-2 text-sm text-gray-500 leading-relaxed text-black">I have done my Bsc degree from MWU by department of Computer Science in 2017
        </p>
        
      </div>
    </div>
</motion.div>

  )
}

export default Education
